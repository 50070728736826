import { navigate, PageProps } from "gatsby"
import React, { ReactElement, Suspense, useCallback, useEffect } from "react"
import MediaQuery, { useMediaQuery } from "react-responsive"
import { LatLng } from "../__generated__/proto/google/type/latlng"
import { Parking } from "../__generated__/proto/itech/motorist/pksha/v1/parking"
import {
  Layout,
  SEO,
  AreaBreadcrumbsRow,
  SearchResultListItem,
  Map,
  SearchResultList,
  ParkingAvailavilityRow,
  SearchFilterChip,
  SearchInputBar,
  MobileSearchHeader,
  SearchResultPagingRow,
  AreaSelectionSection,
} from "../components"
import { useTransaction } from "../components/useTransaction"
import * as config from "../config"
import { GeocodeService, ParkingService } from "../domain/services"
import {
  GetCurrentPositionUseCase,
  ListParkingsUseCase,
  GetRegionsUseCase,
  GeocodeUseCase,
} from "../domain/use-cases"
import { SearchFilterType, SearchOrderType } from "../types"
import {
  SearchParkingsReducerProvider,
  useSearchParkingsReducer,
} from "./search-parkings-reducer"
import * as styles from "./search-parkings.module.scss"
import "react-spring-bottom-sheet/dist/style.css"

// MARK: - UseCases

const listParkingsUseCase = new ListParkingsUseCase(new ParkingService())
const getRegionUseCase = new GetRegionsUseCase()
const getCurrentPositionUseCase = new GetCurrentPositionUseCase()
const geocodeUseCase = new GeocodeUseCase(new GeocodeService())

// MARK: - ReactElement

interface PageContext {
  prefecture?: string
  city?: string
  town?: string
  towns?: string[]
  mapCenter?: LatLng
  zoom?: number
  noindex?: boolean
}

const SearchParkingsPage = (
  props: PageProps<void, PageContext>
): ReactElement => {
  // MARK: - Variables

  const { prefecture, city, town, towns, noindex } = props.pageContext
  const initialMapCenter = props.pageContext.mapCenter
  const initialMapZoom = props.pageContext.zoom
  const selectingCity = prefecture !== undefined && city === undefined
  const selectingTown = prefecture !== undefined && city !== undefined
  const region = getRegionUseCase.call()
  const isMobile = useMediaQuery({ maxWidth: config.RESPONSIVE_BREAKPOINT })
  const parkingPageSize = isMobile ? 20 : 100
  const searchParams = new URLSearchParams(props.location.search)
  const searchQuery = searchParams.get("q")

  // MARK: - States

  const [state, dispatch] = useSearchParkingsReducer()
  const { transaction } = useTransaction({ polling: true })

  // MARK: - Effects

  useEffect(() => {
    if (!initialMapCenter && !searchQuery) {
      getCurrentPositionUseCase
        .call()
        .then(latLng =>
          dispatch({
            type: "SET_CURRENT_MAP_CENTER",
            mapCenter: latLng ?? config.DEFAULT_MAP_CENTER,
          })
        )
        .catch(() =>
          dispatch({
            type: "SET_CURRENT_MAP_CENTER",
            mapCenter: config.DEFAULT_MAP_CENTER,
          })
        )
    } else if (initialMapCenter && !searchQuery) {
      dispatch({
        type: "SET_CURRENT_MAP_CENTER",
        mapCenter: initialMapCenter,
      })
    }
    if (initialMapZoom) {
      dispatch({ type: "SET_CURRENT_MAP_ZOOM", mapZoom: initialMapZoom })
    }
  }, [])

  useEffect(() => {
    if (searchQuery) {
      geocodeUseCase
        .call({ query: searchQuery })
        .then(geocodes => {
          if (geocodes.length > 0) {
            const geocode = geocodes[0]
            if (geocode.latLng) {
              // Changing the center & zoom of map also changes its bounds,
              // and `loadParkingsInMapBounds` will be called
              // in `setCurrentMapBounds` which is set to `Map.onBoundsChanged`.
              dispatch({
                type: "SET_CURRENT_MAP_CENTER",
                mapCenter: geocode.latLng,
              })
              dispatch({ type: "SET_CURRENT_MAP_ZOOM", mapZoom: 16 })

              // Set searchStatus to "loading" for `loadParkingsInMapBounds`
              // to be called in `setCurrentMapBounds`
              dispatch({
                type: "SET_SEARCH_STATUS",
                searchStatus: "loading",
              })
            }
          } else {
            dispatch({
              type: "SET_CURRENT_MAP_CENTER",
              mapCenter: config.DEFAULT_MAP_CENTER,
            })
          }
        })
        .catch(() =>
          dispatch({
            type: "SET_CURRENT_MAP_CENTER",
            mapCenter: config.DEFAULT_MAP_CENTER,
          })
        )
    }
  }, [searchQuery])

  useEffect(() => {
    ;(async () => {
      if (transaction?.latestEvent?.isEntryDetected) {
        await navigate("/reservation-detail")
      }
    })()
  }, [transaction])

  // MARK: - Methods

  const loadParkingsInMapBounds = useCallback(
    (mapBounds: google.maps.LatLngBounds | null) => {
      if (mapBounds === null) {
        return
      }

      dispatch({ type: "LOAD_PARKINGS" })

      const ne = mapBounds.getNorthEast()
      const sw = mapBounds.getSouthWest()

      listParkingsUseCase
        .call({
          pageSize: parkingPageSize,
          boundingBox: {
            high: { latitude: ne.lat(), longitude: ne.lng() },
            low: { latitude: sw.lat(), longitude: sw.lng() },
          },
        })
        .then(response => {
          dispatch({
            type: "LOAD_PARKINGS_SUCCESS",
            parkings: response.parkings,
          })
          dispatch({ type: "SET_SEARCH_STATUS", searchStatus: "pending" })
        })
        .catch(() => {
          dispatch({ type: "LOAD_PARKINGS_FAILURE" })
          dispatch({ type: "SET_SEARCH_STATUS", searchStatus: "pending" })
        })
    },
    []
  )

  const setCurrentPage = useCallback((currentPage: number) => {
    dispatch({ type: "SET_CURRENT_PAGE", currentPage })
  }, [])

  const selectParking = useCallback((parking: Parking | null) => {
    dispatch({ type: "SELECT_PARKING", parking })
  }, [])

  const addsSearchFilter = useCallback(
    (searchFilter: keyof typeof SearchFilterType) => {
      dispatch({ type: "ADD_SEARCH_FILTER", searchFilter })
    },
    []
  )

  const removesSearchFilter = useCallback(
    (searchFilter: keyof typeof SearchFilterType) => {
      dispatch({ type: "REMOVE_SEARCH_FILTER", searchFilter })
    },
    []
  )

  const setSearchOrder = useCallback(
    (searchOrder: keyof typeof SearchOrderType) => {
      dispatch({ type: "SET_SEARCH_ORDER", searchOrder })
    },
    []
  )

  const setCurrentMapBounds = useCallback(
    (mapBounds: google.maps.LatLngBounds) => {
      dispatch({ type: "SET_CURRENT_MAP_BOUNDS", mapBounds })

      if (state.searchStatus === "loading") {
        loadParkingsInMapBounds(mapBounds)
      }
    },
    [state.searchStatus]
  )

  const setCurrentMapCenter = useCallback((mapCenter: LatLng) => {
    dispatch({ type: "SET_CURRENT_MAP_CENTER", mapCenter })
  }, [])

  const setCurrentMapZoom = useCallback((mapZoom: number) => {
    dispatch({ type: "SET_CURRENT_MAP_ZOOM", mapZoom })
  }, [])

  const openMobileModalParkingList = useCallback(() => {
    dispatch({
      type: "SET_MOBILE_BOTTOM_SHEET_OPEN",
      mobileBottomSheetOpen: true,
    })
    const mobileSemiModalHeader = window.document.getElementById(
      "mobileSemiModalHeader"
    )
    window &&
      mobileSemiModalHeader &&
      window.requestAnimationFrame(() =>
        mobileSemiModalHeader.scrollIntoView({
          behavior: "smooth",
          inline: "center",
        })
      )
  }, [])

  const openMobileModalAreaSelection = useCallback(() => {
    dispatch({
      type: "SET_MOBILE_BOTTOM_SHEET_OPEN",
      mobileBottomSheetOpen: true,
    })
    const areaSelectionSection = window.document.getElementById(
      "areaSelectionSection"
    )
    window &&
      areaSelectionSection &&
      window.requestAnimationFrame(() =>
        areaSelectionSection.scrollIntoView({
          behavior: "smooth",
          inline: "center",
        })
      )
  }, [])

  const closeMobileModal = useCallback(() => {
    dispatch({
      type: "SET_MOBILE_BOTTOM_SHEET_OPEN",
      mobileBottomSheetOpen: false,
    })
  }, [])

  const onSelectParkingInMobileMap = useCallback((parking: Parking | null) => {
    dispatch({ type: "SELECT_PARKING", parking })

    if (parking) {
      const item = window.document.getElementById(parking.name)
      window &&
        item &&
        window.requestAnimationFrame(() =>
          item.scrollIntoView({ behavior: "smooth", inline: "center" })
        )
    }
  }, [])

  const navigateToParkingDetail = useCallback(
    (parking: Parking) => {
      navigate(`/${parking.name}`, {
        state: {
          searchPagePath: props.location.pathname + props.location.search,
        },
      })
    },
    [props.location]
  )

  /**
   * Scroll search result list to the cell of selected parking.
   * When selected parking is in current displaying paginated list, just scroll to the cell.
   * Otherwise, scroll to the cell with the same index as the parking cell index in the hidden list, then switch the page.
   * @param parking selected parking
   */
  const onSelectParkingInPCMap = useCallback(
    (parking: Parking | null) => {
      dispatch({ type: "SELECT_PARKING", parking })

      if (parking) {
        const index = state.displayingParkings.indexOf(parking)
        if (index === -1) {
          return
        }
        const page = Math.floor(index / config.PARKINGS_COUNT_PER_PAGE) + 1
        const scrollTargetParking =
          page === state.currentPage
            ? parking
            : state.displayingParkings.slice(
                config.PARKINGS_COUNT_PER_PAGE * (state.currentPage - 1),
                config.PARKINGS_COUNT_PER_PAGE * state.currentPage
              )[index % config.PARKINGS_COUNT_PER_PAGE]

        const list = window.document.getElementById("searchResultList")
        const item = window.document.getElementById(scrollTargetParking.name)
        if (window && list && item) {
          const listY = list.getBoundingClientRect().top + window.scrollY
          const itemY = item.getBoundingClientRect().top + window.scrollY
          window.requestAnimationFrame(() => {
            list.scrollTo({
              top: itemY - listY + list.scrollTop,
              behavior: "smooth",
            })
          })
          if (page !== state.currentPage) {
            dispatch({ type: "SET_CURRENT_PAGE", currentPage: page })
          }
        }
      }
    },
    [state.displayingParkings, state.currentPage]
  )

  // MARK: - React Element

  const MobileBottomSheet = React.lazy(
    () => import("./../components/search-parking/mobile-bottom-sheet")
  )

  return (
    <>
      <SEO
        title={
          town ? `${town}の駐車場` : city ? `${city}の駐車場` : "駐車場検索"
        }
        description={
          town && city && prefecture
            ? `${town}（${prefecture}${city}）周辺のQt-netに加盟している駐車場情報です。`
            : city && prefecture
            ? `${city}（${prefecture}）周辺のQt-netに加盟している駐車場情報です。`
            : "Qt-net駐車場検索では、空き車室のある駐車場、車椅子対応のある駐車場、スマホで支払いができる駐車場、といった条件を絞り込んで駐車場を探すことができます。"
        }
        lang="ja"
        meta={noindex ? [{ name: "robots", content: "noindex" }] : []}
      />
      <MediaQuery maxWidth={config.RESPONSIVE_BREAKPOINT}>
        <div className={styles.mainContainer}>
          <div style={{ position: "fixed", zIndex: 1 }}>
            <MobileSearchHeader
              searchFilters={state.searchFilters}
              onSelectFilter={addsSearchFilter}
              onDeselectFilter={removesSearchFilter}
            />
            <AreaBreadcrumbsRow
              prefecture={prefecture}
              city={city}
              town={town}
              onTapAreaSelection={openMobileModalAreaSelection}
            />
          </div>
          <div className={styles.mobileMap}>
            {state.showSearchButton && (
              <a
                className={styles.searchInMapBoundsButton}
                onClick={() => {
                  loadParkingsInMapBounds(state.currentMapBounds)
                }}
              >
                このエリアで検索
              </a>
            )}
            <Map
              width="100vw"
              height={
                typeof window !== "undefined"
                  ? `${window.innerHeight}px`
                  : "100vh"
              }
              center={state.currentMapCenter}
              zoom={state.currentMapZoom}
              parkings={state.displayingParkings}
              selectedParking={state.selectedParking ?? undefined}
              showCenterMarker={false}
              onSelectParking={onSelectParkingInMobileMap}
              onCenterChanged={setCurrentMapCenter}
              onZoomChanged={setCurrentMapZoom}
              onBoundsLoaded={loadParkingsInMapBounds}
              onBoundsChanged={setCurrentMapBounds}
            />
          </div>
          <a
            style={{
              position: "fixed",
              bottom: state.displayingParkings.length > 0 ? "222px" : "38px",
            }}
            className={styles.openListButton}
            onClick={openMobileModalParkingList}
          >
            一覧で見る
          </a>
          {state.displayingParkings.length > 0 && (
            <div className={styles.mobileSearchResultListOnMap}>
              {state.displayingParkings.map(parking => {
                return (
                  <div
                    className={styles.searchResultListItem}
                    id={parking.name}
                    key={parking.name}
                  >
                    <SearchResultListItem
                      parking={parking}
                      mapCenter={state.currentMapCenter}
                      onClick={selectParking}
                      onClickName={navigateToParkingDetail}
                    />
                  </div>
                )
              })}
            </div>
          )}
        </div>
        <Suspense fallback={<></>}>
          <MobileBottomSheet
            open={state.mobileBottomSheetOpen}
            parkings={state.displayingParkings}
            mapCenter={state.currentMapCenter}
            searchOrder={state.searchOrder}
            onDismiss={closeMobileModal}
            onSelectOrderType={setSearchOrder}
            onClickResultItem={navigateToParkingDetail}
            region={region}
            prefecture={prefecture}
            city={city}
            towns={towns}
            selectingCity={selectingCity}
            selectingTown={selectingTown}
          />
        </Suspense>
      </MediaQuery>
      <MediaQuery minWidth={config.RESPONSIVE_BREAKPOINT}>
        <Layout>
          <div className={styles.mainContainer}>
            {!selectingCity && (
              <div className={styles.searchParkings}>
                <h4 className={styles.sectionTitle}>駐車場検索</h4>
                {selectingTown && (
                  <AreaBreadcrumbsRow
                    prefecture={prefecture}
                    city={city}
                    town={town}
                  />
                )}
                <div className={styles.searchContainer}>
                  <SearchInputBar />
                  <div className={styles.searchFilterRow}>
                    <SearchFilterChip
                      type="AVAILABLE"
                      isSelected={state.searchFilters.includes("AVAILABLE")}
                      onSelect={addsSearchFilter}
                      onDeselect={removesSearchFilter}
                    />
                    <SearchFilterChip
                      type="WHEELCHAIR"
                      isSelected={state.searchFilters.includes("WHEELCHAIR")}
                      onSelect={addsSearchFilter}
                      onDeselect={removesSearchFilter}
                    />
                    <SearchFilterChip
                      type="QT_NET_RESEVABLE"
                      isSelected={state.searchFilters.includes(
                        "QT_NET_RESEVABLE"
                      )}
                      onSelect={addsSearchFilter}
                      onDeselect={removesSearchFilter}
                    />
                    <SearchFilterChip
                      type="QT_NET_POINT"
                      isSelected={state.searchFilters.includes("QT_NET_POINT")}
                      onSelect={addsSearchFilter}
                      onDeselect={removesSearchFilter}
                    />
                    <SearchFilterChip
                      type="QT_NET_SMART_PAY"
                      isSelected={state.searchFilters.includes(
                        "QT_NET_SMART_PAY"
                      )}
                      onSelect={addsSearchFilter}
                      onDeselect={removesSearchFilter}
                    />
                  </div>
                </div>
                <ParkingAvailavilityRow />
                <div className={styles.resultContainer}>
                  <div className={styles.pcMap}>
                    <Map
                      width="600px"
                      height="500px"
                      center={state.currentMapCenter}
                      zoom={state.currentMapZoom}
                      parkings={state.displayingParkings}
                      selectedParking={state.selectedParking ?? undefined}
                      showCenterMarker={false}
                      onSelectParking={onSelectParkingInPCMap}
                      onCenterChanged={setCurrentMapCenter}
                      onZoomChanged={setCurrentMapZoom}
                      onBoundsLoaded={loadParkingsInMapBounds}
                      onBoundsChanged={setCurrentMapBounds}
                    />
                    {state.showSearchButton && (
                      <a
                        className={styles.searchInMapBoundsButton}
                        onClick={() => {
                          loadParkingsInMapBounds(state.currentMapBounds)
                        }}
                      >
                        このエリアで検索
                      </a>
                    )}
                  </div>
                  <div className={styles.searchResult}>
                    <SearchResultPagingRow
                      allParkingCount={state.displayingParkings.length}
                      currentPage={state.currentPage}
                      orderType={state.searchOrder}
                      showOrderSelection={true}
                      onSelectPage={setCurrentPage}
                      onSelectOrder={setSearchOrder}
                    />
                    <div className={styles.searchResultList}>
                      <SearchResultList
                        parkings={state.displayingParkings}
                        currentPage={state.currentPage}
                        mapCenter={state.currentMapCenter}
                        onClickItem={selectParking}
                        onClickParkingName={navigateToParkingDetail}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <AreaSelectionSection
              region={region}
              prefecture={prefecture}
              city={city}
              towns={towns}
              selectingCity={selectingCity}
              selectingTown={selectingTown}
            />
            {!selectingCity && (
              <div className={styles.findFromList}>
                <h4 className={styles.sectionTitle}>リストから探す</h4>
                {state.displayingParkings.length > 0 && (
                  <SearchResultPagingRow
                    allParkingCount={state.displayingParkings.length}
                    currentPage={state.currentPage}
                    orderType={state.searchOrder}
                    showOrderSelection={true}
                    onSelectPage={setCurrentPage}
                    onSelectOrder={setSearchOrder}
                  />
                )}
                <div className={styles.searchResultList}>
                  <SearchResultList
                    parkings={state.displayingParkings}
                    currentPage={state.currentPage}
                    mapCenter={state.currentMapCenter}
                    onClickItem={navigateToParkingDetail}
                    onClickParkingName={navigateToParkingDetail}
                  />
                </div>
                {state.displayingParkings.length > 0 && (
                  <SearchResultPagingRow
                    allParkingCount={state.displayingParkings.length}
                    currentPage={state.currentPage}
                    orderType={state.searchOrder}
                    showOrderSelection={true}
                    onSelectPage={setCurrentPage}
                    onSelectOrder={setSearchOrder}
                  />
                )}
              </div>
            )}
          </div>
        </Layout>
      </MediaQuery>
    </>
  )
}

const SearchParkingsPageInProvider = (
  props: PageProps<void, PageContext>
): ReactElement => {
  return (
    <SearchParkingsReducerProvider>
      <SearchParkingsPage {...props} />
    </SearchParkingsReducerProvider>
  )
}

export default SearchParkingsPageInProvider
